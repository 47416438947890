import { SvgIcon, SvgIconProps } from "@mui/material";
import { FunctionComponent } from "react";

export const RocketIcon: FunctionComponent<SvgIconProps> = (props) => {
  return (
    <SvgIcon {...props} width="512" height="512" viewBox="0 0 512 512">
      <path
        stroke="currentColor"
        d="M424 143.1C424 174.9 398.9 199.1 368 199.1C337.1 199.1 312 174.9 312 143.1C312 113.1 337.1 87.1 368 87.1C398.9 87.1 424 113.1 424 143.1zM368 167.1C381.3 167.1 392 157.3 392 143.1C392 130.7 381.3 119.1 368 119.1C354.7 119.1 344 130.7 344 143.1C344 157.3 354.7 167.1 368 167.1zM118.5 287.1H16C10.45 287.1 5.305 285.1 2.39 280.4C-.5254 275.7-.7907 269.8 1.689 264.8L52.42 163.4C63.26 141.7 85.42 127.1 109.7 127.1H199.9C202.4 124 204.8 120.3 207.2 116.7C289.1-4.07 411.1-8.142 483.9 5.275C495.6 7.414 504.6 16.43 506.7 28.06C520.1 100.9 516.1 222.9 395.3 304.8C391.7 307.2 387.1 309.6 384 312.1V402.3C384 426.6 370.3 448.7 348.6 459.6L247.2 510.3C242.2 512.8 236.3 512.5 231.6 509.6C226.9 506.7 224 501.5 224 496V391.8C215.2 395.6 207.4 398.9 200.1 401.7C189.1 406.6 175.5 403.9 166.5 394.8L115.8 344.1C106.6 334.9 103.9 321.1 109.1 309.2C111.7 303.2 114.9 296 118.5 288L118.5 287.1zM188.9 371.1C231.8 353.9 327.9 311.8 377.3 278.3C481.9 207.4 487.4 103 475.7 36.3C408.1 24.6 304.6 30.08 233.7 134.7C200.3 183.1 157.2 278.1 138.6 321.6L188.9 371.1zM334.3 430.1C345.2 425.5 352 414.5 352 402.3V330.6C321.4 347.3 286.3 363.1 256 377.7V470.1L334.3 430.1zM81.04 177.7L41.89 255.1H133.1C147.1 225.8 164.2 190.7 181.1 159.1H109.7C97.54 159.1 86.46 166.8 81.04 177.7z"
      />
    </SvgIcon>
  );
};
