import { SvgIcon, SvgIconProps } from "@mui/material";
import { FunctionComponent } from "react";

export const CommentDotsIcon: FunctionComponent<SvgIconProps> = (props) => {
  return (
    <SvgIcon {...props} width="89" height="78" viewBox="0 0 89 78">
      <path
        d="M44.2002 0.5C19.7939 0.5 0.200195 16.4844 0.200195 36.25C0.200195 44.6719 3.98145 52.5781 9.99707 58.5938C7.93457 67.3594 0.71582 75.0938 0.71582 75.0938C0.200195 75.4375 0.200195 76.125 0.37207 76.6406C0.543945 77.1562 1.05957 77.5 1.5752 77.5C13.0908 77.5 21.3408 72 25.8096 68.5625C31.4814 70.625 37.6689 72 44.2002 72C68.6064 72 88.0283 55.8438 88.0283 36.25C88.0283 16.6562 68.4346 0.5 44.2002 0.5ZM22.0283 41.75C18.9346 41.75 16.5283 39.1719 16.5283 36.25C16.5283 33.5 18.9346 30.75 22.0283 30.75C24.9502 30.75 27.5283 33.3281 27.5283 36.25C27.5283 39.3438 25.1221 41.75 22.0283 41.75ZM44.2002 41.75C41.1064 41.75 38.7002 39.1719 38.7002 36.25C38.7002 33.5 41.2783 30.75 44.2002 30.75C46.9502 30.75 49.5283 33.3281 49.5283 36.25C49.5283 39.3438 47.1221 41.75 44.2002 41.75ZM66.0283 41.75C62.9346 41.75 60.5283 39.1719 60.5283 36.25C60.5283 33.5 62.9346 30.75 66.0283 30.75C68.9502 30.75 71.5283 33.3281 71.5283 36.25C71.5283 39.3438 69.1221 41.75 66.0283 41.75Z"
        fill="#8D68F8"
      />
    </SvgIcon>
  );
};
