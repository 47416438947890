import { SvgIcon, SvgIconProps } from "@mui/material";
import { FunctionComponent } from "react";

export const BoxesStackedIcon: FunctionComponent<SvgIconProps> = (props) => {
  return (
    <SvgIcon
      {...props}
      width="576"
      height="512"
      viewBox="0 0 576 512"
      fill="#C1CFDE"
    >
      <path
        d="M416 56V168C416 198.9 390.9 224 360 224H216C185.1 224 160 198.9 160 168V56C160 25.07 185.1 0 216 0H360C390.9 0 416 25.07 416 56zM360 32H336V80C336 97.67 321.7 112 304 112H272C254.3 112 240 97.67 240 80V32H216C202.7 32 192 42.75 192 56V168C192 181.3 202.7 192 216 192H360C373.3 192 384 181.3 384 168V56C384 42.75 373.3 32 360 32zM272 80H304V32H272V80zM200 288C230.9 288 256 313.1 256 344V456C256 486.9 230.9 512 200 512H56C25.07 512 0 486.9 0 456V344C0 313.1 25.07 288 56 288H200zM176 368C176 385.7 161.7 400 144 400H112C94.33 400 80 385.7 80 368V320H56C42.75 320 32 330.7 32 344V456C32 469.3 42.75 480 56 480H200C213.3 480 224 469.3 224 456V344C224 330.7 213.3 320 200 320H176V368zM144 320H112V368H144V320zM520 288C550.9 288 576 313.1 576 344V456C576 486.9 550.9 512 520 512H376C345.1 512 320 486.9 320 456V344C320 313.1 345.1 288 376 288H520zM496 368C496 385.7 481.7 400 464 400H432C414.3 400 400 385.7 400 368V320H376C362.7 320 352 330.7 352 344V456C352 469.3 362.7 480 376 480H520C533.3 480 544 469.3 544 456V344C544 330.7 533.3 320 520 320H496V368zM464 320H432V368H464V320z"
        fill="inherit"
      />
    </SvgIcon>
  );
};
