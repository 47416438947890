import { SvgIcon, SvgIconProps } from "@mui/material";
import { FunctionComponent } from "react";

export const OpenBookIcon: FunctionComponent<SvgIconProps> = (props) => {
  return (
    <SvgIcon {...props} viewBox="0 0 16 14">
      <path
        d="M15.0619 0.779146C13.5397 0.865536 10.5141 1.17999 8.64626 2.32334C8.51737 2.40223 8.44431 2.54251 8.44431 2.68918V12.7969C8.44431 13.1177 8.79515 13.3205 9.09099 13.1716C11.0127 12.2043 13.7919 11.9405 15.1661 11.8682C15.6353 11.8435 16 11.4674 16 11.0165V1.63194C16.0003 1.13999 15.5736 0.750257 15.0619 0.779146ZM7.35374 2.32334C5.48621 1.17999 2.4606 0.865814 0.93835 0.779146C0.426674 0.750257 0 1.13999 0 1.63194V11.0168C0 11.4679 0.364729 11.8441 0.833903 11.8685C2.20865 11.9407 4.98925 12.2049 6.91095 13.1727C7.20596 13.3213 7.55569 13.1188 7.55569 12.7988V2.68418C7.55569 2.53723 7.48291 2.40251 7.35374 2.32334Z"
        fill="#64778C"
      />
    </SvgIcon>
  );
};
