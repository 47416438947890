import { SvgIcon, SvgIconProps } from "@mui/material";
import { FunctionComponent } from "react";

export const SolidSparklesIcon: FunctionComponent<SvgIconProps> = (props) => {
  return (
    <SvgIcon
      {...props}
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="#C1CFDE"
    >
      <path
        d="M20.4375 5.375L24 4L25.3125 0.5C25.375 0.1875 25.6875 0 26 0C26.25 0 26.5625 0.1875 26.625 0.5L28 4L31.5 5.375C31.8125 5.4375 32 5.75 32 6C32 6.3125 31.8125 6.625 31.5 6.6875L28 8L26.625 11.5625C26.5625 11.8125 26.25 12 26 12C25.6875 12 25.375 11.8125 25.3125 11.5625L24 8L20.4375 6.6875C20.125 6.625 20 6.3125 20 6C20 5.75 20.125 5.4375 20.4375 5.375ZM16.0625 11.75L23.1875 15C23.5625 15.1875 23.8125 15.5625 23.8125 15.9375C23.8125 16.3125 23.5625 16.6875 23.1875 16.875L16.0625 20.125L12.8125 27.25C12.625 27.625 12.25 27.875 11.875 27.875C11.5 27.875 11.125 27.625 11 27.25L7.6875 20.125L0.5625 16.875C0.1875 16.6875 0 16.3125 0 15.9375C0 15.5625 0.1875 15.1875 0.5625 15L7.6875 11.75L11 4.625C11.125 4.25 11.5 4 11.875 4C12.25 4 12.625 4.25 12.8125 4.625L16.0625 11.75ZM25.3125 20.5C25.375 20.1875 25.6875 20 26 20C26.25 20 26.5625 20.1875 26.625 20.5L28 24L31.5 25.375C31.8125 25.4375 32 25.75 32 26C32 26.3125 31.8125 26.625 31.5 26.6875L28 28L26.625 31.5625C26.5625 31.8125 26.25 32 26 32C25.6875 32 25.375 31.8125 25.3125 31.5625L24 28L20.4375 26.6875C20.125 26.625 20 26.3125 20 26C20 25.75 20.125 25.4375 20.4375 25.375L24 24L25.3125 20.5Z"
        fill="inherit"
      />
    </SvgIcon>
  );
};
