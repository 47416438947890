import { SvgIcon, SvgIconProps } from "@mui/material";
import { FunctionComponent } from "react";

export const CityIcon: FunctionComponent<SvgIconProps> = (props) => {
  return (
    <SvgIcon {...props} width="640" height="512" viewBox="0 0 640 512">
      <path
        stroke="currentColor"
        d="M96 64H160V16C160 7.164 167.2 0 176 0C184.8 0 192 7.164 192 16V80C192 88.84 184.8 96 176 96H64C46.33 96 32 110.3 32 128V448C32 465.7 46.33 480 64 480H192C200.8 480 208 487.2 208 496C208 504.8 200.8 512 192 512H64C28.65 512 0 483.3 0 448V128C0 92.65 28.65 64 64 64V16C64 7.164 71.16 0 80 0C88.84 0 96 7.164 96 16V64zM320 344C320 330.7 330.7 320 344 320H392C405.3 320 416 330.7 416 344V392C416 405.3 405.3 416 392 416H344C330.7 416 320 405.3 320 392V344zM384 384V352H352V384H384zM392 64C405.3 64 416 74.75 416 88V136C416 149.3 405.3 160 392 160H344C330.7 160 320 149.3 320 136V88C320 74.75 330.7 64 344 64H392zM384 128V96H352V128H384zM320 216C320 202.7 330.7 192 344 192H392C405.3 192 416 202.7 416 216V264C416 277.3 405.3 288 392 288H344C330.7 288 320 277.3 320 264V216zM384 256V224H352V256H384zM504 448C490.7 448 480 437.3 480 424V376C480 362.7 490.7 352 504 352H552C565.3 352 576 362.7 576 376V424C576 437.3 565.3 448 552 448H504zM512 384V416H544V384H512zM480 248C480 234.7 490.7 224 504 224H552C565.3 224 576 234.7 576 248V296C576 309.3 565.3 320 552 320H504C490.7 320 480 309.3 480 296V248zM544 288V256H512V288H544zM512 64V160H576C611.3 160 640 188.7 640 224V448C640 483.3 611.3 512 576 512H288C252.7 512 224 483.3 224 448V64C224 28.65 252.7 0 288 0H448C483.3 0 512 28.65 512 64zM480 192V64C480 46.33 465.7 32 448 32H288C270.3 32 256 46.33 256 64V448C256 465.7 270.3 480 288 480H576C593.7 480 608 465.7 608 448V224C608 206.3 593.7 192 576 192H480zM88 352C74.75 352 64 341.3 64 328V280C64 266.7 74.75 256 88 256H136C149.3 256 160 266.7 160 280V328C160 341.3 149.3 352 136 352H88zM96 288V320H128V288H96zM64 152C64 138.7 74.75 128 88 128H136C149.3 128 160 138.7 160 152V200C160 213.3 149.3 224 136 224H88C74.75 224 64 213.3 64 200V152zM128 192V160H96V192H128z"
      />
    </SvgIcon>
  );
};
