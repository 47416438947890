import { SvgIcon, SvgIconProps } from "@mui/material";
import { FunctionComponent } from "react";

export const BlockProtocolLogoIcon: FunctionComponent<
  SvgIconProps & { iconColor?: string; logoColor?: string }
> = ({
  iconColor = "currentColor",
  logoColor = "currentColor",
  sx = [],
  ...remainingProps
}) => {
  return (
    <SvgIcon
      {...remainingProps}
      sx={[
        { width: "unset", height: "unset" },
        ...(Array.isArray(sx) ? sx : [sx]),
      ]}
      width="168"
      height="18"
      viewBox="0 0 168 18"
    >
      <path
        d="M6.75231 3.6H3.63586V2.05714C3.63586 1.51155 3.41697 0.988315 3.02733 0.602526C2.6377 0.216736 2.10925 0 1.55823 0H0V15.9429C0 16.4884 0.218891 17.0117 0.608523 17.3975C0.998155 17.7833 1.52661 18 2.07763 18H3.63586V14.4H6.75231C8.16205 14.3472 9.49629 13.7556 10.4748 12.7494C11.4533 11.7432 12 10.4007 12 9.00386C12 7.60702 11.4533 6.26453 10.4748 5.25832C9.49629 4.25212 8.16205 3.6605 6.75231 3.60771V3.6ZM6.75231 10.8H3.63586V7.2H6.75231C7.79112 7.2 8.50531 8.01386 8.50531 9C8.50531 9.98614 7.79112 10.7961 6.75231 10.7961V10.8Z"
        fill={iconColor}
      />
      <path
        d="M24 1.17877H29.7207C31.0912 1.17877 32.1788 1.53631 32.9832 2.2514C33.7877 2.96648 34.1899 4.01676 34.1899 5.40223C34.1899 6.04283 34.0559 6.62384 33.7877 7.14525C33.5196 7.65177 33.1397 8.03166 32.648 8.28491C34.2123 8.92551 34.9944 10.1993 34.9944 12.1061C34.9944 13.6108 34.5326 14.7728 33.6089 15.5922C32.7002 16.4115 31.4413 16.8212 29.8324 16.8212H24V1.17877ZM29.5642 7.25698C30.175 7.25698 30.6294 7.13035 30.9274 6.87709C31.2402 6.60894 31.3966 6.16946 31.3966 5.55866C31.3966 4.94786 31.2402 4.51583 30.9274 4.26257C30.6145 3.99441 30.1378 3.86033 29.4972 3.86033H26.905V7.25698H29.5642ZM29.5642 14.1397C30.4432 14.1397 31.0838 13.9609 31.486 13.6034C31.8883 13.2458 32.0894 12.7244 32.0894 12.0391C32.0894 11.3538 31.8883 10.8324 31.486 10.4749C31.0838 10.1173 30.4432 9.93855 29.5642 9.93855H26.905V14.1397H29.5642Z"
        fill={logoColor}
      />
      <path d="M36.8454 1H39.6163V16.8212H36.8454V1Z" fill={logoColor} />
      <path
        d="M47.46 17C46.2831 17 45.2626 16.7467 44.3986 16.2402C43.5345 15.7337 42.8716 15.041 42.4097 14.162C41.9628 13.2831 41.7394 12.2924 41.7394 11.1899C41.7394 10.0875 41.9628 9.09684 42.4097 8.21788C42.8716 7.33892 43.5345 6.64618 44.3986 6.13966C45.2626 5.63315 46.2831 5.37989 47.46 5.37989C48.6369 5.37989 49.6574 5.63315 50.5215 6.13966C51.3855 6.64618 52.041 7.33892 52.488 8.21788C52.9498 9.09684 53.1807 10.0875 53.1807 11.1899C53.1807 12.2924 52.9498 13.2831 52.488 14.162C52.041 15.041 51.3855 15.7337 50.5215 16.2402C49.6574 16.7467 48.6369 17 47.46 17ZM47.46 14.6313C48.3241 14.6313 49.0094 14.3035 49.5159 13.648C50.0224 12.9777 50.2757 12.1583 50.2757 11.1899C50.2757 10.2216 50.0224 9.40968 49.5159 8.75419C49.0094 8.0838 48.3241 7.7486 47.46 7.7486C46.596 7.7486 45.9107 8.0838 45.4042 8.75419C44.8976 9.40968 44.6444 10.2216 44.6444 11.1899C44.6444 12.1583 44.8976 12.9777 45.4042 13.648C45.9107 14.3035 46.596 14.6313 47.46 14.6313Z"
        fill={logoColor}
      />
      <path
        d="M59.8035 17C58.6266 17 57.621 16.7467 56.7867 16.2402C55.9674 15.7188 55.3491 15.0186 54.932 14.1397C54.5148 13.2607 54.3063 12.2775 54.3063 11.1899C54.3063 10.1024 54.5148 9.11918 54.932 8.24022C55.3491 7.36127 55.9674 6.66853 56.7867 6.16201C57.621 5.6406 58.6266 5.37989 59.8035 5.37989C61.0549 5.37989 62.1126 5.65549 62.9767 6.2067C63.8556 6.74302 64.5037 7.54004 64.9208 8.59776L62.4404 9.60335C62.1424 8.99255 61.7774 8.53073 61.3454 8.21788C60.9283 7.90503 60.4143 7.7486 59.8035 7.7486C58.9543 7.7486 58.3063 8.0689 57.8594 8.7095C57.4273 9.3352 57.2113 10.162 57.2113 11.1899C57.2113 12.2179 57.4273 13.0521 57.8594 13.6927C58.3063 14.3184 58.9543 14.6313 59.8035 14.6313C60.4143 14.6313 60.9283 14.4749 61.3454 14.162C61.7774 13.8492 62.1424 13.3873 62.4404 12.7765L64.9208 13.7821C64.5037 14.8399 63.8556 15.6443 62.9767 16.1955C62.1126 16.7318 61.0549 17 59.8035 17Z"
        fill={logoColor}
      />
      <path
        d="M66.7802 1H69.5512V9.98324L73.216 5.55866H76.635L72.121 10.743L76.9255 16.8212H73.5288L69.5512 11.5028V16.8212H66.7802V1Z"
        fill={logoColor}
      />
      <path
        d="M84.3963 1.17877H90.2958C91.8898 1.17877 93.104 1.61825 93.9382 2.49721C94.7725 3.36127 95.1896 4.50838 95.1896 5.93855C95.1896 7.36871 94.7725 8.52328 93.9382 9.40223C93.104 10.2663 91.8898 10.6983 90.2958 10.6983H87.3013V16.8212H84.3963V1.17877ZM90.184 8.01676C90.8395 8.01676 91.346 7.84544 91.7036 7.50279C92.0611 7.16015 92.2399 6.63873 92.2399 5.93855C92.2399 5.23836 92.0611 4.71695 91.7036 4.3743C91.346 4.03166 90.8395 3.86033 90.184 3.86033H87.3013V8.01676H90.184Z"
        fill={logoColor}
      />
      <path
        d="M97.1137 5.55866H99.8846V7.39106C100.287 6.75047 100.778 6.25884 101.359 5.9162C101.94 5.55866 102.559 5.37989 103.214 5.37989C103.631 5.37989 104.086 5.45438 104.577 5.60335L104.063 8.17318C103.721 7.96462 103.333 7.86033 102.901 7.86033C102.022 7.86033 101.3 8.26257 100.734 9.06704C100.168 9.85661 99.8846 11.108 99.8846 12.8212V16.8212H97.1137V5.55866Z"
        fill={logoColor}
      />
      <path
        d="M111.003 17C109.826 17 108.806 16.7467 107.942 16.2402C107.078 15.7337 106.415 15.041 105.953 14.162C105.506 13.2831 105.282 12.2924 105.282 11.1899C105.282 10.0875 105.506 9.09684 105.953 8.21788C106.415 7.33892 107.078 6.64618 107.942 6.13966C108.806 5.63315 109.826 5.37989 111.003 5.37989C112.18 5.37989 113.201 5.63315 114.065 6.13966C114.929 6.64618 115.584 7.33892 116.031 8.21788C116.493 9.09684 116.724 10.0875 116.724 11.1899C116.724 12.2924 116.493 13.2831 116.031 14.162C115.584 15.041 114.929 15.7337 114.065 16.2402C113.201 16.7467 112.18 17 111.003 17ZM111.003 14.6313C111.867 14.6313 112.553 14.3035 113.059 13.648C113.566 12.9777 113.819 12.1583 113.819 11.1899C113.819 10.2216 113.566 9.40968 113.059 8.75419C112.553 8.0838 111.867 7.7486 111.003 7.7486C110.139 7.7486 109.454 8.0838 108.947 8.75419C108.441 9.40968 108.188 10.2216 108.188 11.1899C108.188 12.1583 108.441 12.9777 108.947 13.648C109.454 14.3035 110.139 14.6313 111.003 14.6313Z"
        fill={logoColor}
      />
      <path
        d="M123.647 17C122.351 17 121.338 16.6499 120.608 15.9497C119.878 15.2495 119.513 14.2142 119.513 12.8436V7.92737H117.77V5.55866H119.513V2.74302L122.284 1V5.55866H125.256V7.92737H122.284V12.7989C122.284 13.4693 122.403 13.946 122.641 14.2291C122.894 14.4972 123.267 14.6313 123.758 14.6313C124.131 14.6313 124.488 14.5419 124.831 14.3631L125.479 16.5978C124.898 16.8659 124.287 17 123.647 17Z"
        fill={logoColor}
      />
      <path
        d="M132.325 17C131.148 17 130.128 16.7467 129.264 16.2402C128.4 15.7337 127.737 15.041 127.275 14.162C126.828 13.2831 126.605 12.2924 126.605 11.1899C126.605 10.0875 126.828 9.09684 127.275 8.21788C127.737 7.33892 128.4 6.64618 129.264 6.13966C130.128 5.63315 131.148 5.37989 132.325 5.37989C133.502 5.37989 134.523 5.63315 135.387 6.13966C136.251 6.64618 136.906 7.33892 137.353 8.21788C137.815 9.09684 138.046 10.0875 138.046 11.1899C138.046 12.2924 137.815 13.2831 137.353 14.162C136.906 15.041 136.251 15.7337 135.387 16.2402C134.523 16.7467 133.502 17 132.325 17ZM132.325 14.6313C133.189 14.6313 133.875 14.3035 134.381 13.648C134.888 12.9777 135.141 12.1583 135.141 11.1899C135.141 10.2216 134.888 9.40968 134.381 8.75419C133.875 8.0838 133.189 7.7486 132.325 7.7486C131.461 7.7486 130.776 8.0838 130.269 8.75419C129.763 9.40968 129.51 10.2216 129.51 11.1899C129.51 12.1583 129.763 12.9777 130.269 13.648C130.776 14.3035 131.461 14.6313 132.325 14.6313Z"
        fill={logoColor}
      />
      <path
        d="M144.669 17C143.492 17 142.486 16.7467 141.652 16.2402C140.833 15.7188 140.214 15.0186 139.797 14.1397C139.38 13.2607 139.172 12.2775 139.172 11.1899C139.172 10.1024 139.38 9.11918 139.797 8.24022C140.214 7.36127 140.833 6.66853 141.652 6.16201C142.486 5.6406 143.492 5.37989 144.669 5.37989C145.92 5.37989 146.978 5.65549 147.842 6.2067C148.721 6.74302 149.369 7.54004 149.786 8.59776L147.306 9.60335C147.008 8.99255 146.643 8.53073 146.211 8.21788C145.794 7.90503 145.28 7.7486 144.669 7.7486C143.82 7.7486 143.172 8.0689 142.725 8.7095C142.293 9.3352 142.077 10.162 142.077 11.1899C142.077 12.2179 142.293 13.0521 142.725 13.6927C143.172 14.3184 143.82 14.6313 144.669 14.6313C145.28 14.6313 145.794 14.4749 146.211 14.162C146.643 13.8492 147.008 13.3873 147.306 12.7765L149.786 13.7821C149.369 14.8399 148.721 15.6443 147.842 16.1955C146.978 16.7318 145.92 17 144.669 17Z"
        fill={logoColor}
      />
      <path
        d="M156.499 17C155.322 17 154.302 16.7467 153.438 16.2402C152.573 15.7337 151.911 15.041 151.449 14.162C151.002 13.2831 150.778 12.2924 150.778 11.1899C150.778 10.0875 151.002 9.09684 151.449 8.21788C151.911 7.33892 152.573 6.64618 153.438 6.13966C154.302 5.63315 155.322 5.37989 156.499 5.37989C157.676 5.37989 158.696 5.63315 159.56 6.13966C160.424 6.64618 161.08 7.33892 161.527 8.21788C161.989 9.09684 162.22 10.0875 162.22 11.1899C162.22 12.2924 161.989 13.2831 161.527 14.162C161.08 15.041 160.424 15.7337 159.56 16.2402C158.696 16.7467 157.676 17 156.499 17ZM156.499 14.6313C157.363 14.6313 158.048 14.3035 158.555 13.648C159.061 12.9777 159.315 12.1583 159.315 11.1899C159.315 10.2216 159.061 9.40968 158.555 8.75419C158.048 8.0838 157.363 7.7486 156.499 7.7486C155.635 7.7486 154.95 8.0838 154.443 8.75419C153.937 9.40968 153.683 10.2216 153.683 11.1899C153.683 12.1583 153.937 12.9777 154.443 13.648C154.95 14.3035 155.635 14.6313 156.499 14.6313Z"
        fill={logoColor}
      />
      <path d="M164.343 1H167.114V16.8212H164.343V1Z" fill={logoColor} />
    </SvgIcon>
  );
};
