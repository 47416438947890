import { SvgIcon, SvgIconProps } from "@mui/material";
import { FunctionComponent } from "react";

export const AtomSimpleIcon: FunctionComponent<SvgIconProps> = (props) => {
  return (
    <SvgIcon {...props} width="448" height="512" viewBox="0 0 448 512">
      <path
        stroke="currentColor"
        d="M426.9 53.49c-26.75-26.63-73.37-28.63-131.4-5.377c-23.25 9.252-47.37 22.63-71.5 38.5c-24.12-15.88-48.25-29.25-71.37-38.5C94.51 24.87 47.88 26.87 21.13 53.49c-38.37 38.25-22.75 118 32.75 202.5c-55.5 84.5-71.12 164.3-32.75 202.5C35.51 472.9 55.63 480 80.13 480c21.12 0 45.62-5.375 72.5-16c23.12-9.25 47.25-22.75 71.37-38.63C248.1 441.3 272.3 454.8 295.5 464c26.75 10.62 51.13 16 72.37 16c24.5 0 44.62-7.125 58.1-21.5c38.38-38.25 22.75-118-32.87-202.5C449.6 171.5 465.2 91.74 426.9 53.49zM307.4 77.74c44.75-17.75 79.1-18.38 96.87-1.625c22.87 22.88 12.87 82.38-30 151.6c-17.38-23-36.37-44.63-56.87-65c-20.38-20.12-42.12-38.88-65.12-56C271 95.12 289.5 84.87 307.4 77.74zM43.76 76.12c8.25-8.125 20.62-12.12 36.5-12.12c16.75 0 37.37 4.625 60.37 13.75c17.88 7.125 36.5 17.38 55.12 29c-46.25 34.38-87.25 75.13-121.1 121C30.88 158.5 20.88 99.12 43.76 76.12zM140.6 434.3c-44.62 17.88-79.87 18.38-96.87 1.625c-22.88-22.88-12.88-82.38 29.1-151.6c17.38 23 36.38 44.63 56.87 65c20.38 20.12 42.13 38.88 65.12 56.13C177.1 416.9 158.5 427.1 140.6 434.3zM153.3 326.6c-22-21.88-42.25-45.5-60.37-70.63c18.12-25.12 38.37-48.75 60.37-70.63c21.88-21.88 45.62-42 70.75-60.13c25.12 18.12 48.75 38.25 70.75 60.13c22 21.88 42.25 45.5 60.37 70.63c-18.12 25.12-38.37 48.75-60.37 70.63c-21.1 21.88-45.62 42-70.75 60.13C198.9 368.6 175.3 348.5 153.3 326.6zM404.2 435.9c-16.88 16.75-52.25 16.25-96.87-1.625c-17.87-7.125-36.5-17.38-55.12-28.88c23-17.25 44.75-36 65.12-56.13c20.5-20.38 39.5-42 56.87-65C417.1 353.5 427.1 412.1 404.2 435.9zM224 232c-13.25 0-24 10.72-24 24.06C199.9 269.3 210.8 280 224 280c6.375 0 12.5-2.494 17-6.982S248 262.4 248 256.1C248 242.8 237.3 232 224 232z"
      />
    </SvgIcon>
  );
};
