import { SvgIcon, SvgIconProps } from "@mui/material";
import { FunctionComponent } from "react";

export const ContentfulIcon: FunctionComponent<SvgIconProps> = (props) => {
  return (
    <SvgIcon {...props} width="78" height="88" viewBox="0 0 78 88">
      <path
        d="M26.6173 61.1361C21.9567 56.7497 19.2151 50.7183 19.2151 43.8645C19.2151 37.0107 21.9567 30.9793 26.3431 26.5928C30.1812 22.7547 30.1812 16.7233 26.3431 12.8852C22.505 9.04706 16.4736 9.04706 12.6355 12.8852C4.95917 20.8356 0.0244141 31.8018 0.0244141 43.8645C0.0244141 55.9272 4.95917 66.8933 12.9096 74.8438C16.7477 78.6819 22.7791 78.6819 26.6173 74.8438C30.1812 71.0056 30.1812 64.9743 26.6173 61.1361Z"
        fill="#FAE501"
      />
      <path
        d="M26.6175 26.5928C31.004 21.9322 37.0353 19.1907 43.8891 19.1907C50.743 19.1907 56.7743 21.9322 61.1608 26.3187C64.9989 30.1568 71.0303 30.1568 74.8684 26.3187C78.7066 22.4805 78.7066 16.4492 74.8684 12.611C66.918 4.93475 55.9519 0 43.8891 0C31.8264 0 20.8603 4.93475 12.9099 12.8852C9.07171 16.7233 9.07171 22.7547 12.9099 26.5928C16.748 30.1568 22.7794 30.1568 26.6175 26.5928Z"
        fill="#4FB5E1"
      />
      <path
        d="M61.1608 61.1361C56.7743 65.7967 50.743 68.5383 43.8891 68.5383C37.0353 68.5383 31.004 65.7967 26.6175 61.4103C22.7794 57.5721 16.748 57.5721 12.9099 61.4103C9.07171 65.2484 9.07171 71.2798 12.9099 75.1179C20.8603 82.7942 31.8264 87.729 43.8891 87.729C55.9519 87.729 66.918 82.7942 74.8684 74.8438C78.7066 71.0056 78.7066 64.9743 74.8684 61.1361C71.0303 57.5721 64.9989 57.5721 61.1608 61.1361Z"
        fill="#F05751"
      />
      <path
        d="M19.7633 29.3344C25.0627 29.3344 29.3587 25.0384 29.3587 19.739C29.3587 14.4397 25.0627 10.1437 19.7633 10.1437C14.464 10.1437 10.168 14.4397 10.168 19.739C10.168 25.0384 14.464 29.3344 19.7633 29.3344Z"
        fill="#0681B6"
      />
      <path
        d="M19.7633 77.5854C25.0627 77.5854 29.3587 73.2894 29.3587 67.99C29.3587 62.6906 25.0627 58.3947 19.7633 58.3947C14.464 58.3947 10.168 62.6906 10.168 67.99C10.168 73.2894 14.464 77.5854 19.7633 77.5854Z"
        fill="#CD4739"
      />
    </SvgIcon>
  );
};
