import { SvgIcon, SvgIconProps } from "@mui/material";
import { FunctionComponent } from "react";

export const MapboxIcon: FunctionComponent<SvgIconProps> = ({
  sx,
  ...props
}) => {
  return (
    <SvgIcon
      {...props}
      width="82"
      height="19"
      viewBox="0 0 82 19"
      sx={[{ color: "#64778C" }, ...(Array.isArray(sx) ? sx : [sx])]}
    >
      <title>Mapbox</title>
      <path
        stroke="currentColor"
        d="M256 0C397.4 0 512 114.6 512 256C512 397.4 397.4 512 256 512C114.6 512 0 397.4 0 256C0 114.6 114.6 0 256 0zM256 480C272.7 480 296.4 465.6 317.9 422.7C327.8 402.9 336.1 378.1 341.1 352H170C175.9 378.1 184.2 402.9 194.1 422.7C215.6 465.6 239.3 480 256 480V480zM164.3 320H347.7C350.5 299.8 352 278.3 352 256C352 233.7 350.5 212.2 347.7 192H164.3C161.5 212.2 160 233.7 160 256C160 278.3 161.5 299.8 164.3 320V320zM341.1 160C336.1 133 327.8 109.1 317.9 89.29C296.4 46.37 272.7 32 256 32C239.3 32 215.6 46.37 194.1 89.29C184.2 109.1 175.9 133 170 160H341.1zM379.1 192C382.6 212.5 384 233.9 384 256C384 278.1 382.6 299.5 379.1 320H470.7C476.8 299.7 480 278.2 480 256C480 233.8 476.8 212.3 470.7 192H379.1zM327.5 43.66C348.5 71.99 365.1 112.4 374.7 160H458.4C432.6 105.5 385.3 63.12 327.5 43.66V43.66zM184.5 43.66C126.7 63.12 79.44 105.5 53.56 160H137.3C146.9 112.4 163.5 71.99 184.5 43.66V43.66zM32 256C32 278.2 35.24 299.7 41.28 320H132C129.4 299.5 128 278.1 128 256C128 233.9 129.4 212.5 132 192H41.28C35.24 212.3 32 233.8 32 256V256zM458.4 352H374.7C365.1 399.6 348.5 440 327.5 468.3C385.3 448.9 432.6 406.5 458.4 352zM137.3 352H53.56C79.44 406.5 126.7 448.9 184.5 468.3C163.5 440 146.9 399.6 137.3 352V352z"
      />
      <path
        d="M60.7504 5.48008C59.7387 5.48008 58.7679 5.89008 58.0628 6.61008V2.80008C58.0628 2.68008 57.9606 2.58008 57.838 2.58008H56.4687C56.3461 2.58008 56.2439 2.68008 56.2439 2.80008V13.1001C56.2439 13.2201 56.3461 13.3201 56.4687 13.3201H57.838C57.9606 13.3201 58.0628 13.2201 58.0628 13.1001V12.3901C58.7679 13.1101 59.7285 13.5201 60.7504 13.5201C62.8861 13.5201 64.6131 11.7201 64.6131 9.50008C64.6131 7.28008 62.8861 5.48008 60.7504 5.48008ZM60.4336 11.9101C59.1358 11.9101 58.0833 10.8501 58.0731 9.53008V9.47008C58.0935 8.15008 59.1358 7.09008 60.4336 7.09008C61.7416 7.09008 62.7941 8.17008 62.7941 9.50008C62.7941 10.8301 61.7314 11.9101 60.4336 11.9101Z"
        fill="currentColor"
      />
      <path
        d="M69.6509 5.47998C67.3415 5.47998 65.4714 7.27998 65.4714 9.49998C65.4714 11.72 67.3415 13.52 69.6509 13.52C71.9603 13.52 73.8304 11.72 73.8304 9.49998C73.8304 7.27998 71.9603 5.47998 69.6509 5.47998ZM69.6407 11.91C68.3327 11.91 67.2802 10.83 67.2802 9.49998C67.2802 8.16998 68.3429 7.08998 69.6407 7.08998C70.9385 7.08998 72.0012 8.16998 72.0012 9.49998C72.0012 10.83 70.9385 11.91 69.6407 11.91Z"
        fill="currentColor"
      />
      <path
        d="M44.0938 5.67998H42.7245C42.6019 5.67998 42.4997 5.77998 42.4997 5.89998V6.60998C41.7946 5.88998 40.834 5.47998 39.8122 5.47998C37.6764 5.47998 35.9495 7.27998 35.9495 9.49998C35.9495 11.72 37.6764 13.52 39.8122 13.52C40.8238 13.52 41.7946 13.11 42.4997 12.39V13.1C42.4997 13.22 42.6019 13.32 42.7245 13.32H44.0938C44.2164 13.32 44.3186 13.22 44.3186 13.1V5.89998C44.3186 5.77998 44.2164 5.67998 44.0938 5.67998ZM40.1289 11.91C38.8209 11.91 37.7684 10.83 37.7684 9.49998C37.7684 8.16998 38.8312 7.08998 40.1289 7.08998C41.4267 7.08998 42.4792 8.14998 42.4895 9.46998V9.52998C42.4792 10.85 41.4267 11.91 40.1289 11.91Z"
        fill="currentColor"
      />
      <path
        d="M50.9301 5.47998C49.9184 5.47998 48.9476 5.88998 48.2425 6.60998V5.89998C48.2425 5.77998 48.1403 5.67998 48.0177 5.67998H46.6484C46.5258 5.67998 46.4236 5.77998 46.4236 5.89998V16.2C46.4236 16.32 46.5258 16.42 46.6484 16.42H48.0177C48.1403 16.42 48.2425 16.32 48.2425 16.2V12.39C48.9476 13.11 49.9082 13.52 50.9301 13.52C53.0658 13.52 54.7927 11.72 54.7927 9.49998C54.7927 7.27998 53.0658 5.47998 50.9301 5.47998ZM50.6133 11.91C49.3155 11.91 48.263 10.85 48.2527 9.52998V9.46998C48.2732 8.14998 49.3155 7.08998 50.6133 7.08998C51.9213 7.08998 52.9738 8.16998 52.9738 9.49998C52.9738 10.83 51.9213 11.91 50.6133 11.91Z"
        fill="currentColor"
      />
      <path
        d="M31.8517 5.48011C30.8298 5.49011 29.8999 6.07011 29.4605 6.98011C28.9598 6.05011 27.9584 5.47011 26.8854 5.48011C26.0475 5.48011 25.2606 5.88011 24.7701 6.54011V5.90011C24.7701 5.78011 24.6679 5.68011 24.5453 5.68011H23.176C23.0534 5.68011 22.9512 5.78011 22.9512 5.90011V13.1001C22.9512 13.2201 23.0534 13.3201 23.176 13.3201H24.5453C24.6679 13.3201 24.7701 13.2201 24.7701 13.1001V8.79011C24.8212 7.83011 25.5059 7.06011 26.3438 7.06011C27.2124 7.06011 27.9379 7.77011 27.9379 8.70011V13.1001C27.9379 13.2201 28.0401 13.3201 28.1627 13.3201H29.5423C29.6649 13.3201 29.7671 13.2201 29.7671 13.1001L29.7569 8.62011C29.8795 7.74011 30.5233 7.06011 31.3101 7.06011C32.1787 7.06011 32.9042 7.77011 32.9042 8.70011V13.1001C32.9042 13.2201 33.0064 13.3201 33.129 13.3201H34.5086C34.6312 13.3201 34.7334 13.2201 34.7334 13.1001L34.7232 8.15011C34.7232 6.67011 33.4356 5.48011 31.8517 5.48011Z"
        fill="currentColor"
      />
      <path
        d="M81.1981 13.0102L78.8273 9.48017L81.1776 5.98017C81.239 5.89017 81.2083 5.76017 81.1163 5.70017C81.0857 5.68017 81.0448 5.67017 81.0039 5.67017H79.42C79.2974 5.67017 79.185 5.73017 79.1237 5.83017L77.7441 8.10017L76.3646 5.84017C76.3033 5.74017 76.1909 5.68017 76.0683 5.68017H74.4844C74.372 5.68017 74.28 5.77017 74.28 5.88017C74.28 5.92017 74.2902 5.96017 74.3106 5.99017L76.6609 9.49017L74.2902 13.0202C74.2289 13.1102 74.2595 13.2402 74.3515 13.3002C74.3822 13.3202 74.423 13.3302 74.4639 13.3302H76.0478C76.1704 13.3302 76.2829 13.2702 76.3442 13.1702L77.7544 10.8702L79.1645 13.1702C79.2259 13.2702 79.3383 13.3302 79.4609 13.3302H81.0244C81.1368 13.3302 81.2287 13.2402 81.2287 13.1302C81.2287 13.0902 81.2185 13.0502 81.1981 13.0102Z"
        fill="currentColor"
      />
      <path
        d="M9.58496 0.610107C4.56755 0.610107 0.500488 4.59011 0.500488 9.50011C0.500488 14.4101 4.56755 18.3901 9.58496 18.3901C14.6024 18.3901 18.6694 14.4101 18.6694 9.50011C18.6694 4.59011 14.6024 0.610107 9.58496 0.610107ZM13.8973 11.6801C10.7908 14.7201 5.24199 13.7501 5.24199 13.7501C5.24199 13.7501 4.24055 8.33011 7.35727 5.28011C9.08424 3.59011 11.9455 3.66011 13.7542 5.42011C15.5629 7.18011 15.6242 9.99011 13.8973 11.6801Z"
        fill="currentColor"
      />
      <path
        d="M10.6274 5.82007L9.73834 7.61007L7.90918 8.48007L9.73834 9.35007L10.6274 11.1401L11.5164 9.35007L13.3456 8.48007L11.5164 7.61007L10.6274 5.82007Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};
