import { SvgIcon, SvgIconProps } from "@mui/material";
import { FunctionComponent } from "react";

export const CollapseIcon: FunctionComponent<SvgIconProps> = (props) => {
  return (
    <SvgIcon
      {...props}
      width="32"
      height="32"
      viewBox="0 0 32 32"
      style={{ fill: "none" }}
    >
      <path
        d="M13.6403 17.5608H9.92079C9.2101 17.5608 8.85476 18.4175 9.35622 18.9222L10.4488 19.9515L7.15442 23.2485C6.94853 23.4544 6.94853 23.7931 7.15442 23.9989L7.99795 24.8423C8.20385 25.0481 8.54259 25.0481 8.74849 24.8423L12.0462 21.5452L13.079 22.6409C13.5805 23.1423 14.4373 22.787 14.4373 22.0765V18.3577C14.4373 17.9161 14.0819 17.5608 13.6403 17.5608ZM17.3597 15.4358H21.0792C21.7899 15.4358 22.1452 14.5759 21.6438 14.0745L20.5479 13.0452L23.8456 9.74814C24.0515 9.54229 24.0515 9.20361 23.8456 8.99775L23.0021 8.15439C22.7962 7.94854 22.4574 7.94854 22.2515 8.15439L18.9538 11.4515L17.921 10.3558C17.4195 9.85439 16.5627 10.2097 16.5627 10.9202V14.639C16.5627 15.0806 16.9181 15.4358 17.3597 15.4358ZM20.5479 19.9515L21.6438 18.9188C22.1452 18.4175 21.7899 17.5608 21.0792 17.5608H17.3597C16.9181 17.5608 16.5627 17.9161 16.5627 18.3577V22.0765C16.5627 22.787 17.4228 23.1423 17.9243 22.6409L18.9538 21.5485L22.2515 24.8456C22.4574 25.0515 22.7962 25.0515 23.0021 24.8456L23.8456 24.0022C24.0515 23.7964 24.0515 23.4577 23.8456 23.2519L20.5479 19.9515ZM13.0757 10.3591L12.0462 11.4515L8.74849 8.15439C8.54259 7.94854 8.20385 7.94854 7.99795 8.15439L7.15442 8.99775C6.94853 9.20361 6.94853 9.54229 7.15442 9.74814L10.4521 13.0452L9.35622 14.0778C8.85476 14.5792 9.2101 15.4358 9.92079 15.4358H13.6403C14.0819 15.4358 14.4373 15.0806 14.4373 14.639V10.9202C14.4373 10.213 13.5772 9.85771 13.0757 10.3591Z"
        fill="currentColor"
      />
      <rect
        x="0.5"
        y="0.5"
        width="31"
        height="31"
        rx="1.5"
        stroke="currentColor"
      />
    </SvgIcon>
  );
};
