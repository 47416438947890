import { SvgIcon, SvgIconProps } from "@mui/material";
import { FunctionComponent } from "react";

export const SparklesIcon: FunctionComponent<SvgIconProps> = (props) => {
  return (
    <SvgIcon
      {...props}
      width="512"
      height="512"
      viewBox="0 0 512 512"
      fill="#C1CFDE"
    >
      <path
        d="M432 32C440.8 32 448 39.16 448 48V96H496C504.8 96 512 103.2 512 112C512 120.8 504.8 128 496 128H448V176C448 184.8 440.8 192 432 192C423.2 192 416 184.8 416 176V128H368C359.2 128 352 120.8 352 112C352 103.2 359.2 96 368 96H416V48C416 39.16 423.2 32 432 32zM432 320C440.8 320 448 327.2 448 336V384H496C504.8 384 512 391.2 512 400C512 408.8 504.8 416 496 416H448V464C448 472.8 440.8 480 432 480C423.2 480 416 472.8 416 464V416H368C359.2 416 352 408.8 352 400C352 391.2 359.2 384 368 384H416V336C416 327.2 423.2 320 432 320zM123.3 321.8L9.292 269.1C3.627 266.5 0 260.8 0 254.6C0 248.3 3.627 242.6 9.292 240L123.3 187.3L176 73.29C178.6 67.63 184.3 64 190.6 64C196.8 64 202.5 67.63 205.1 73.29L257.8 187.3L371.8 240C377.5 242.6 381.1 248.3 381.1 254.6C381.1 260.8 377.5 266.5 371.8 269.1L257.8 321.8L205.1 435.8C202.5 441.5 196.8 445.1 190.6 445.1C184.3 445.1 178.6 441.5 176 435.8L123.3 321.8zM54.16 254.6L136.8 292.7C143.7 295.9 149.2 301.4 152.4 308.3L190.6 390.9L228.7 308.3C231.9 301.4 237.4 295.9 244.3 292.7L326.9 254.6L244.3 216.4C237.4 213.2 231.9 207.7 228.7 200.8L190.6 118.2L152.4 200.8C149.2 207.7 143.7 213.2 136.8 216.4L54.16 254.6z"
        fill="inherit"
      />
    </SvgIcon>
  );
};
