import { SvgIcon, SvgIconProps } from "@mui/material";
import { FunctionComponent } from "react";

export const ExpandIcon: FunctionComponent<SvgIconProps> = ({
  ...svgProps
}) => {
  return (
    <SvgIcon
      width="32"
      height="32"
      viewBox="0 0 32 32"
      style={{ fill: "none" }}
      {...svgProps}
    >
      <path
        d="M24 19.1429V23.1429C24.0003 23.2555 23.9783 23.3671 23.9353 23.4712C23.8924 23.5753 23.8292 23.6699 23.7496 23.7496C23.6699 23.8292 23.5753 23.8924 23.4712 23.9353C23.3671 23.9783 23.2555 24.0003 23.1429 24H19.1429C18.3789 24 17.9968 23.075 18.5357 22.5357L19.8286 21.2429L16 17.4143L12.1704 21.2464L13.4643 22.5357C14.0032 23.075 13.6211 24 12.8571 24H8.85715C8.7445 24.0003 8.63292 23.9783 8.5288 23.9353C8.42468 23.8924 8.33007 23.8292 8.25042 23.7496C8.17077 23.6699 8.10765 23.5753 8.06467 23.4712C8.0217 23.3671 7.99972 23.2555 8 23.1429V19.1429C8 18.3786 8.92465 17.9964 9.46429 18.5357L10.7568 19.8286L14.5879 16L10.7564 12.1679L9.46429 13.4643C8.925 14.0036 8 13.6214 8 12.8571V8.85715C7.99972 8.7445 8.0217 8.63292 8.06467 8.5288C8.10765 8.42468 8.17077 8.33007 8.25042 8.25042C8.33007 8.17077 8.42468 8.10765 8.5288 8.06467C8.63292 8.0217 8.7445 7.99972 8.85715 8H12.8571C13.6211 8 14.0032 8.925 13.4643 9.46429L12.1714 10.7571L16 14.5857L19.8296 10.7536L18.5357 9.46429C17.9968 8.925 18.3789 8 19.1429 8H23.1429C23.2555 7.99972 23.3671 8.0217 23.4712 8.06467C23.5753 8.10765 23.6699 8.17077 23.7496 8.25042C23.8292 8.33007 23.8924 8.42468 23.9353 8.5288C23.9783 8.63292 24.0003 8.7445 24 8.85715V12.8571C24 13.6214 23.0754 14.0036 22.5357 13.4643L21.2432 12.1714L17.4121 16L21.2436 19.8321L22.5357 18.5393C23.075 17.9964 24 18.3786 24 19.1429Z"
        fill="#64778C"
      />
      <rect x="0.5" y="0.5" width="31" height="31" rx="1.5" stroke="#64778C" />
    </SvgIcon>
  );
};
