import { SvgIcon, SvgIconProps } from "@mui/material";
import { FunctionComponent } from "react";

export const WandMagicSparklesIcon: FunctionComponent<SvgIconProps> = (
  props,
) => {
  return (
    <SvgIcon {...props} width="576" height="512" viewBox="0 0 576 512">
      <path
        stroke="currentColor"
        d="M492.3 12.28L531.7 51.72C547.3 67.34 547.3 92.66 531.7 108.3L140.3 499.7C124.7 515.3 99.34 515.3 83.72 499.7L44.28 460.3C28.66 444.7 28.66 419.3 44.28 403.7L435.7 12.28C451.3-3.337 476.7-3.337 492.3 12.28V12.28zM458.3 34.91L337.9 155.3L388.7 206.1L509.1 85.66C512.2 82.53 512.2 77.47 509.1 74.34L469.7 34.91C466.5 31.79 461.5 31.79 458.3 34.91L458.3 34.91zM66.91 437.7L106.3 477.1C109.5 480.2 114.5 480.2 117.7 477.1L366.1 228.7L315.3 177.9L66.91 426.3C63.79 429.5 63.79 434.5 66.91 437.7L66.91 437.7zM128 127.1H176C184.8 127.1 192 135.2 192 143.1C192 152.8 184.8 159.1 176 159.1H128V207.1C128 216.8 120.8 223.1 112 223.1C103.2 223.1 96 216.8 96 207.1V159.1H48C39.16 159.1 32 152.8 32 143.1C32 135.2 39.16 127.1 48 127.1H96V79.1C96 71.16 103.2 63.1 112 63.1C120.8 63.1 128 71.16 128 79.1V127.1zM464 319.1C472.8 319.1 480 327.2 480 336V384H528C536.8 384 544 391.2 544 400C544 408.8 536.8 416 528 416H480V464C480 472.8 472.8 480 464 480C455.2 480 448 472.8 448 464V416H400C391.2 416 384 408.8 384 400C384 391.2 391.2 384 400 384H448V336C448 327.2 455.2 319.1 464 319.1zM252.2 35.84H272C278.7 35.84 284.2 41.28 284.2 47.1C284.2 54.72 278.7 60.16 272 60.16H252.2V79.1C252.2 86.72 246.7 92.16 240 92.16C233.3 92.16 227.8 86.72 227.8 79.1V60.16H208C201.3 60.16 195.8 54.72 195.8 47.1C195.8 41.28 201.3 35.84 208 35.84H227.8V15.1C227.8 9.284 233.3 3.84 240 3.84C246.7 3.84 252.2 9.284 252.2 15.1V35.84z"
      />
    </SvgIcon>
  );
};
