import { SvgIcon, SvgIconProps } from "@mui/material";
import { FunctionComponent } from "react";

export const PullRequestIcon: FunctionComponent<SvgIconProps> = (props) => {
  return (
    <SvgIcon {...props} width="20" height="20" viewBox="0 0 20 20">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.92857 5.42836C6.55975 5.42836 7.07143 4.91669 7.07143 4.28551C7.07143 3.65432 6.55975 3.14265 5.92857 3.14265C5.29739 3.14265 4.78571 3.65432 4.78571 4.28551C4.78571 4.91669 5.29739 5.42836 5.92857 5.42836ZM9.35714 4.28551C9.35714 5.77833 8.40308 7.04831 7.07143 7.51898V9.81329C7.591 9.56647 8.17223 9.42836 8.78571 9.42836H12.2143C13.1611 9.42836 13.9286 8.66084 13.9286 7.71407C13.9286 7.64624 13.9315 7.58226 13.937 7.52193C12.6009 7.05364 11.6429 5.78146 11.6429 4.28551C11.6429 2.39196 13.1779 0.856934 15.0714 0.856934C16.965 0.856934 18.5 2.39196 18.5 4.28551C18.5 5.78208 17.5411 7.05469 16.2042 7.52252C16.2143 7.63684 16.2143 7.71407 16.2143 7.71407C16.2143 9.92321 14.4234 11.7141 12.2143 11.7141H8.78571C8.15117 11.7141 7.59715 12.0588 7.30075 12.5713C8.51125 13.1005 9.35714 14.3086 9.35714 15.7142C9.35714 17.6078 7.82212 19.1428 5.92857 19.1428C4.03502 19.1428 2.5 17.6078 2.5 15.7142C2.5 14.2214 3.45407 12.9514 4.78571 12.4807V7.51898C3.45407 7.04831 2.5 5.77833 2.5 4.28551C2.5 2.39196 4.03502 0.856934 5.92857 0.856934C7.82212 0.856934 9.35714 2.39196 9.35714 4.28551ZM5.92857 16.8571C6.55975 16.8571 7.07143 16.3454 7.07143 15.7142C7.07143 15.083 6.55975 14.5714 5.92857 14.5714C5.29739 14.5714 4.78571 15.083 4.78571 15.7142C4.78571 16.3454 5.29739 16.8571 5.92857 16.8571ZM16.2143 4.28551C16.2143 4.91669 15.7026 5.42836 15.0714 5.42836C14.4402 5.42836 13.9286 4.91669 13.9286 4.28551C13.9286 3.65432 14.4402 3.14265 15.0714 3.14265C15.7026 3.14265 16.2143 3.65432 16.2143 4.28551Z"
        fill="currentColor"
      />
    </SvgIcon>
  );
};
