import { SvgIcon, SvgIconProps } from "@mui/material";
import { FunctionComponent } from "react";

export const BoltIcon: FunctionComponent<SvgIconProps> = (props) => {
  return (
    <SvgIcon {...props} width="20" height="20" viewBox="0 0 20 20">
      <path
        stroke="currentColor"
        d="M17.1835 9.84175C17.1538 9.69348 17.0825 9.55673 16.978 9.4475C16.8734 9.33827 16.7399 9.26108 16.5931 9.22496L11.1052 7.85218L13.1347 3.11547C13.2054 2.9511 13.2189 2.76774 13.1729 2.59481C13.1269 2.42189 13.0242 2.26943 12.8811 2.16189C12.7384 2.05365 12.5633 1.99667 12.3842 2.00015C12.2051 2.00363 12.0324 2.06738 11.894 2.18109L3.09412 9.38096C2.97934 9.47485 2.89291 9.59881 2.8445 9.73898C2.79608 9.87915 2.78758 10.03 2.81994 10.1747C2.85231 10.3195 2.92426 10.4523 3.02777 10.5585C3.13128 10.6647 3.26227 10.7401 3.40611 10.7761L8.78122 12.1209L6.10527 16.804C6.03623 16.9257 6.00027 17.0633 6.00097 17.2032C6.00166 17.3431 6.03898 17.4803 6.10922 17.6013C6.17945 17.7223 6.28015 17.8227 6.40129 17.8927C6.52242 17.9626 6.65977 17.9996 6.79965 18C6.97279 18.0003 7.14131 17.9441 7.27964 17.84L16.8795 10.6401C17.0004 10.5494 17.0932 10.4262 17.147 10.2849C17.2008 10.1436 17.2134 9.98995 17.1835 9.84175Z"
      />
    </SvgIcon>
  );
};
