import { SvgIcon, SvgIconProps } from "@mui/material";
import { FunctionComponent } from "react";

interface WordpressIconProps {
  dark?: boolean;
}

export const WordPressIcon: FunctionComponent<
  SvgIconProps & WordpressIconProps
> = ({ dark, ...props }) => {
  return (
    <SvgIcon {...props} width="49" height="48" viewBox="0 0 49 48">
      <path
        d="M3.74144 23.9994C3.74144 31.9791 8.47731 38.8751 15.3447 42.143L5.52391 15.795C4.38156 18.3022 3.74144 21.0768 3.74144 23.9994Z"
        fill={dark ? "inherit" : "#26629E"}
      />
      <path
        d="M38.228 22.9821C38.228 20.4906 37.3141 18.7652 36.5302 17.4222C35.4865 15.7616 34.5083 14.3553 34.5083 12.6947C34.5083 10.8415 35.9437 9.11646 37.9655 9.11646C38.0568 9.11646 38.1434 9.12758 38.2323 9.13257C34.5695 5.84664 29.6894 3.8404 24.3295 3.8404C17.137 3.8404 10.809 7.45393 7.12773 12.9272C7.61076 12.9413 8.06597 12.9513 8.45263 12.9513C10.6061 12.9513 13.9395 12.6955 13.9395 12.6955C15.0493 12.6314 15.1802 14.2276 14.0715 14.3561C14.0715 14.3561 12.9562 14.4846 11.7151 14.5483L19.2124 36.3848L23.718 23.1532L20.5103 14.5475C19.4017 14.4838 18.3514 14.3553 18.3514 14.3553C17.2419 14.2916 17.372 12.6306 18.4814 12.6947C18.4814 12.6947 21.8814 12.9506 23.9044 12.9506C26.0575 12.9506 29.3913 12.6947 29.3913 12.6947C30.5019 12.6306 30.6324 14.2268 29.5233 14.3553C29.5233 14.3553 28.4057 14.4838 27.1669 14.5475L34.6071 36.2187L36.6606 29.4991C37.5507 26.7107 38.228 24.7079 38.228 22.9821Z"
        fill={dark ? "inherit" : "#26629E"}
      />
      <path
        d="M24.6907 25.7628L18.5136 43.3391C20.3579 43.87 22.3085 44.1604 24.3295 44.1604C26.727 44.1604 29.0262 43.7545 31.1663 43.0176C31.1111 42.9313 31.0609 42.8396 31.0198 42.7399L24.6907 25.7628Z"
        fill={dark ? "inherit" : "#26629E"}
      />
      <path
        d="M42.3947 14.3273C42.4832 14.9694 42.5333 15.6588 42.5333 16.4003C42.5333 18.446 42.1432 20.7457 40.9679 23.6212L34.6791 41.4253C40.7999 37.9303 44.9168 31.4371 44.9168 23.9998C44.9171 20.4948 44.0028 17.1989 42.3947 14.3273Z"
        fill={dark ? "inherit" : "#26629E"}
      />
      <path
        d="M24.3295 0.5C11.0966 0.5 0.330078 11.0417 0.330078 23.9994C0.330078 36.9586 11.0966 47.5 24.3295 47.5C37.562 47.5 48.3301 36.9586 48.3301 23.9994C48.3297 11.0417 37.562 0.5 24.3295 0.5ZM24.3295 46.4228C11.7034 46.4228 1.43051 36.3637 1.43051 23.9994C1.43051 11.636 11.703 1.57754 24.3295 1.57754C36.9552 1.57754 47.2273 11.636 47.2273 23.9994C47.2273 36.3637 36.9552 46.4228 24.3295 46.4228Z"
        fill={dark ? "inherit" : "#26629E"}
      />
    </SvgIcon>
  );
};
